import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';


export default function SessionHistoryPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [session, setSession] = useState<{
    id: string,
    user_id: string,
    messages: {
      id: string,
      role: string,
      content: string,
      time: number,
      marks: any
    }[],
    timestamp: number,
    summary: string,
  }>();

  const accessToken = localStorage.getItem('tmp::access_token');

  useEffect(() => {
    axios.get(`${globalThis.dzenyUrl}/v1/user_session/${params.sessionId}`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
      }
    })
      .then(function(response) {
        setSession({
          ...response.data,
          messages: response.data.messages.map((msg: any) => {
            const date = new Date(msg.timestamp * 10);
            const formattedDate = new Intl.DateTimeFormat(
              'en-US',
              { hour: '2-digit', minute: '2-digit' }
            ).format(date);
            return { ...msg, time: formattedDate };
          })
        })
      })
      .catch(function(error) {
        console.log(error);
      })
  }, []);

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <header className="bg-gray-900 text-white p-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold">История сессии</h1>
        <button
          onClick={() => navigate("/profile")}
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          aria-label="Go to home page"
        >
          Назад
        </button>
      </header>
      <main className="flex-grow overflow-y-auto p-4">
        <div className="max-w-2xl mx-auto space-y-4">
          {session && session.messages.map((message) => (
            <div
              key={message.id}
              className={`flex flex-col ${message.role === 'user' ? 'items-end' : 'items-start'
                }`}
            >
              <div
                className={`max-w-xs md:max-w-md rounded-lg p-3 ${message.role === 'user' ? 'bg-blue-500 text-white' : 'bg-white'
                  }`}
              >
                <div className="flex justify-between items-baseline mb-1">
                  <span className="text-xs opacity-75">{message.time}</span>
                </div>
                <p>{message.content}</p>
              </div>
            </div>
          ))}
          {session && session.summary &&
            <div key='summary' className='flex flex-col items-center'>
              <div className='max-w-xs md:max-w-md rounded-lg p-3 bg-gray-200'>
                <div className="flex justify-between items-baseline mb-1">
                  <span className="text-xs opacity-75">Самари</span>
                </div>
                <p>{session.summary}</p>
              </div>
            </div>
          }
        </div>
      </main>
    </div>
  )
}
